<template>
  <v-card
    :disabled="cargando"
    :loading="cargando"
    flat
  >
    <p>Si no cuentas con un R.F.C lo puedes obtener fácilmente <a
        href="https://www.sat.gob.mx/tramites/operacion/28753/obten-tu-rfc-con-la-clave-unica-de-registro-de-poblacion-curp"
        target="_blank"
        style="color:#ed1b2e"
      >dando click en este enlace</a></p>
    <v-row class="mt24">
      <v-col
        cols="12"
        sm="6"
      >
        <v-select
          label="Tipo de persona*"
          filled
          v-model="donador.tipo_don"
          :items="[{value:'fisica',text:'Persona física'},{value:'moral',text:'Persona moral'}]"
          :error-messages="errors.tipo_don"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="donador.rfc_don"
          v-mask="donador.tipo_don ==='fisica' ? 'NNNNNNNNNNNNN':'NNNNNNNNNNNN'"
          label="R.F.C.*"
          filled
          :error-messages="errors.rfc_don"
          hint="El RFC es necesario para poder iniciar sesión"
          persistent-hint
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="donador.razon_social_don"
          label="Nombre de la empresa o persona física*"
          filled
          :error-messages="errors.razon_social_don"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <AutocompleteDataEstados
          label="Estado*"
          v-model="donador.id_edo_don"
          :error="errors.id_edo_don"
          isSelect
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <AutocompleteDataMunicipios
          label="Municipio*"
          v-model="donador.id_mpio_don"
          :estado="donador.id_edo_don"
          :error="errors.id_mpio_don"
          isSelect
        />
      </v-col>
      <v-col
        cols="12"
        sm="8"
      >
        <v-text-field
          v-model="donador.calle_don"
          label="Calle*"
          filled
          :error-messages="errors.calle_don"
        />
      </v-col>
      <v-col
        cols="12"
        sm="2"
      >
        <v-text-field
          v-model="donador.num_ext_don"
          label="# ext*"
          filled
          :error-messages="errors.num_ext_don"
        />
      </v-col>
      <v-col
        cols="12"
        sm="2"
      >
        <v-text-field
          v-model="donador.num_int_don"
          label="# int"
          filled
          :error-messages="errors.num_int_don"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="donador.colonia_don"
          label="Colonia*"
          filled
          :error-messages="errors.colonia_don"
        />
      </v-col>
      <v-col
        cols="12"
        sm="3"
      >
        <v-text-field
          v-model="donador.cp_don"
          label="CP*"
          filled
          :error-messages="errors.cp_don"
        />
      </v-col>
    </v-row>
    <v-card-subtitle>Información de contacto{{showPasswordField?' y accesos a la plataforma' : ''}}</v-card-subtitle>
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="6"
      >
        <v-text-field
          v-model="donador.contacto_dco"
          label="Persona de contacto*"
          filled
          :error-messages="errors.contacto_dco"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="6"
      >
        <v-text-field
          v-model="donador.tel_dco"
          label="Teléfono fijo*"
          v-mask="'### ### ####'"
          filled
          :error-messages="errors.tel_dco"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="6"
      >
        <v-text-field
          v-model="donador.email_dco"
          label="Correo electrónico*"
          filled
          :error-messages="errors.email_dco"
          hint="El correo electrónico es necesario para poder iniciar sesión"
          persistent-hint
        />
      </v-col>
    </v-row>
    <ButtonCardActions
      to="/empresas/login"
      cancelText="Ya estoy registrado"
      text="Registrarme"
      @click="_preSaveDon"
    />
  </v-card>
</template>
<script>
import forms from "@/mixins/forms.js";
export default {
  name: "AmbulanciasRegistro",
  props: {
    simple: Boolean,
    full: Boolean,
    buttonText: String,
    showPasswordField: Boolean,
    esRegistro: String,
  },
  mixins: [forms],
  components: {
    AutocompleteDataEstados: () =>
      import("@/components/forms/autocompletes/AutocompleteDataEstados"),
    AutocompleteDataMunicipios: () =>
      import("@/components/forms/autocompletes/AutocompleteDataMunicipios"),
    ButtonCardActions: () =>
      import("@/components/forms/buttons/ButtonCardActions"),
  },
  data() {
    return {
      donador: {
        id_don: null,
        crm_don: null,
        id_edo_don: null,
        id_mpio_don: null,
        tipo_don: null,
        rfc_don: null,
        razon_social_don: null,
        rep_lega_don: null,
        calle_don: null,
        num_ext_don: null,
        num_int_don: null,
        colonia_don: null,
        cp_don: null,
        observaciones_don: null,
        tel_dco: null,
        email_dco: null,
        contacto_dco: null,
        password_dco: 123,
      },
      errors: {
        id_edo_don: null,
        id_mpio_don: null,
        tipo_don: null,
        rfc_don: null,
        razon_social_don: null,
        calle_don: null,
        num_ext_don: null,
        colonia_don: null,
        cp_don: null,
        email_dco: null,
        contacto_dco: null,
        password_dco: null,
      },
      passRepeat: null,
      cargando: false,
    };
  },
  methods: {
    _preSaveDon() {
      if (this._validateAll("donador")) {
        this.donador.tel_don = this.donador.tel_dco;
        this.donador.cel_don = this.donador.tel_dco;
        const data = {
          donador: this.donador,
          registro: this.esRegistro,
        };
        if (this.esRegistro === "si") {
          this._saveAll("sesion", data, "id_don", "_doEmitMessages");
        } else {
          this._updateAll("sesion", data, "msg", "_doEmitMessagesUpdate");
        }
      } else {
        this.$emit("msg", this.$lleneCampos);
      }
    },
    _doEmitMessages(res) {
      this.donador.id_don = res.body.id_don;
      this.donador.id = res.body.id;
      this.$emit(
        "msg",
        "¡Te has registrado correctamente! A continuación inicia sesión"
      );
      this.$emit("saved", this.donador);
    },
    _doEmitMessages(res) {
      this.$emit("msg", "Se ha actualizado tu información");
      this.$emit("saved", this.donador);
    },
  },
};
</script>